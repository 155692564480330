import React, { useCallback, useEffect, useState } from "react";
import { IoArrowBack, IoSearch, IoOptions } from "react-icons/io5";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { FaVial, FaBookOpen } from "react-icons/fa"; // Importing React icons (you can customize icons as needed)
import Footer from "../Layout/Footer";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showOrder } from "../store/reducer/orderReducer";
import { formateDate } from "../../formateDate";


const Digestion = () => {
  const { orderdata } = useSelector((state) => state.order); // Select order data from state
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState("")
  const [page, setPage] = useState(Number(1))
  const [limit, setLimit] = useState(Number(10))

  useEffect(() => {
    dispatch(showOrder({orderId, page, limit})); // Fetch orders when the component mounts
  }, [dispatch, page, limit]);

  const getData = useCallback(() => {
   
      dispatch(showOrder({orderId, page, limit}));

  }, [dispatch,orderId, page, limit]);




  useEffect(() => {
    getData()

  }, [dispatch, getData]);
  const getStatusBadge = (statusNumber) => {
    switch (statusNumber) {
      case "Pending":
        return (
          <div className="bg-yellow-500 px-2 py-1 text-sm text-center text-white">
            Pending
          </div>
        );
      case "Completed":
        return (
          <div className="bg-green-500 px-2 py-1 text-sm text-center text-white">
          Completed
          </div>
        );
      case "Accepted":
        return (
          <div className="bg-purple-500 px-2 py-1 text-sm text-center text-white">
          Accepted
          </div>
        );
      case "Canceled":
        return (
          <div className="bg-red-500 px-2 py-1 text-sm text-center text-white">
           Canceled
          </div>
        );
      default:
        return <div className="badge badge-ghost">Unknown</div>;
    }
  };

  return (
    <div className="">
      <div className="w-full overflow-hidden min-h-screen">
        <div className="p-4">
          <div className="flex items-center justify-between mb-4">
            <Link to="/">
              <IoArrowBack className="text-gray-600 text-xl" />
            </Link>
            <h1 className="text-lg font-semibold text-gray-700 text-center flex-grow">
              Orders
            </h1>
            <HiOutlineDotsVertical className="text-gray-600 text-xl" />
          </div>
          <div className="relative mb-6">
            <IoSearch className="absolute left-3 top-[44%]  text-gray-400" />
            <IoOptions className="absolute right-3 top-[44%] text-gray-400" />
            <input
              type="text"
              placeholder="Search"
              className="text-sm bg-white text-gray-700 w-full py-3 px-8 mt-2 border-[0.5px] border-border rounded-md focus:border-gray-300 focus:outline-none"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)} // Set search state on input change
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  getData(); // Trigger search when Enter key is pressed
                }
              }}
            />
          </div>
          <div className="test-cards-container">
            {orderdata?.map((test, index) => (
              <Link
                to={`/orderdetail/${test._id}`}
                key={test._id}
                className="card bg-white p-4 mb-4"
              >
                <div className="flex items-center justify-between ">
                  <div className="flex gap-1 items-center">
                    <div className="icon bg-gray-100 p-3 text-3xl rounded-lg mr-4 text-[#23aafa]">
                      <FaBookOpen />
                    </div>
                    <div>
                      <h3 className="text-base text-gray-700 font-[500]">
                       Order No. {test.orderId}
                      </h3>
                      <p className="text-gray-500 text-[12px]">{formateDate(test.createAt)}</p>
                      <p className="text-gray-500 text-[13px]">
                        Doctor name: {test?.appointment?.name}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-between items-center">
                    <div className="text-white px-2 py-1 rounded text-[12px]">
                    {getStatusBadge(test.status)}
                    </div>

                    <div className="text-sm font-[500] text-gray-600">
                      ₹{test.amount}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Digestion;
