import React, { useCallback, useEffect, useState } from "react";
import img1 from "../assets/WhatsApp Image 2024-09-24 at 6.57.25 AM.jpeg";
import { IoArrowBack, IoOptions, IoSearch, IoSearchOutline } from "react-icons/io5";
import { MdOutlineStar } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import Footer from '../Layout/Footer'
import { useDispatch, useSelector } from "react-redux"
import { getDoctor, getuserDoctor, searchDoctor } from "../store/reducer/doctorReducer";
import { getUserDetails } from "../store/reducer/authReducer";
import { HiOutlineDotsVertical } from "react-icons/hi";


const Doctorlist = () => {
  const { userdoctorDetail } = useSelector((state) => state.doctor)
  const { userDetail } = useSelector((state) => state.auth)

  const [search, setSearch] = useState("")
  const [name, setName] = useState("")
  const [page, setPage] = useState(Number(1))
  const [limit, setLimit] = useState(Number(50))

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const city = queryParams.get("city");

  const dispatch = useDispatch()
  const getData = useCallback(() => {


    if (search == "") {
      dispatch(getuserDoctor({ name, city, page, limit }))
    }
  }, [search, dispatch])


  const searchData = useCallback(() => {
    if (name) {
      dispatch(getuserDoctor({ name, city, page, limit }));
    }
  }, [name, dispatch]);




  useEffect(() => {
    getData()

  }, [dispatch, getData]);

  return (
    <div className="min-h-screen gap-4">



      <div className=" p-6">

        <div className="flex items-center justify-between mb-4 ">
          <Link to="/location">
            <IoArrowBack className="text-gray-600 text-xl" />
          </Link>
          <h1 className="text-lg font-semibold text-gray-700 text-center flex-grow">
            Doctors
          </h1>
          <div className="h-[50px] w-[50px] overflow-hidden rounded-full">
           

            <img src={userDetail?.photo?userDetail?.photo: img1}  className="object-cover h-full w-full" />
          </div>
        </div>


        <div className="relative w-full">
          <input
            type="text"
            placeholder="Enter the doctor name"
            className="text-sm bg-white text-gray-700 w-full py-3 px-8 mt-2 border-[0.5px] border-border rounded-md focus:border-gray-300 focus:outline-none"
            value={name}
            onChange={(e) => setName(e.target.value)} // Set search state on input change
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                searchData(); // Trigger search when Enter key is pressed
              }
            }}
          />
          <span className="text-gray-500 text-lg absolute left-2 top-[44%]">
            <IoSearchOutline />
          </span>
        </div>
      </div>
      <div className="p-4">
        <div className="rounded-md bg-[#1e78c0] p-4 flex justify-between ">
          <div className="w-[60%] flex flex-col justify-between">
            <h2 className="text-lg text-white font-bold">
              Do you feel there are symptonuse in your body
            </h2>
            <div>
              <button className="bg-[#db2b6a] font-[500] text-white text-[10px] rounded-[4px] p-2 mt-1">
                Start Consulation
              </button>
            </div>
          </div>
          <div className="w-[40%] flex justify-end items-center">
            <img
              src={img1}
              alt="img"
              className="h-[100px] w-[100px] rounded-full object-cover "
            />
          </div>
        </div>
        <div className="flex justify-between mt-4 py-2">
          <h2 className="text-base text-gray-600 font-semibold">
            Popuar Doctors
          </h2>
          <p className="text-sm text-gray-400">see all</p>
        </div>
        <div className="flex flex-col gap-5 mt-1">
          {Array.isArray(userdoctorDetail) && userdoctorDetail.map((item, index) => (
            <Link key={index} to={`/doctorDetail/${item?.slug}`}>
              <div className="flex bg-white p-2 rounded-md">
                <div
                  className={`rounded-md p-4 ${index % 3 === 0 ? 'bg-[#fffcc0]' : index % 3 === 1 ? 'bg-[#fdd1e0]' : 'bg-[#cce5ff]'}`} // Conditional classes
                >
                  <img
                    src={item.photo}
                    className="w-[50px] h-[50px] object-cover rounded-full overflow-hidden"
                    alt=""
                  />
                </div>
                <div className="px-4 py-2 space-y-1">
                  <h4 className="text-sm font-medium">{item?.hospital}</h4>
                  <h2 className="text-sm font-semibold">{item?.name}</h2>
                  <p className="text-[12px] text-gray-500">{item?.location}</p>
                  <div className="flex items-center gap-1">
                    <span className="text-xl text-[#ffd726]">
                      <MdOutlineStar />
                    </span>
                    <span className="text-sm font-semibold">{item?.rate}</span>
                    <p className="text-[12px] font-semibold text-[#1e78c0]">
                      ({item?.review} reviews)
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
          {userdoctorDetail?.length==0&&(
            <div>
              <h2 className="text-center flex justify-center items-center h-full m-auto text-[#1e78c0]">We will update as soon as posible</h2>
            </div>
          )}

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Doctorlist;
