import { useCallback, useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import { HiOfficeBuilding } from "react-icons/hi";
import { FaCartPlus } from "react-icons/fa6";
import { FaPhoneSquare } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, user_reset } from "../store/reducer/authReducer";
import Cookies from "js-cookie";
import profile from "../assets/WhatsApp Image 2024-09-24 at 6.57.25 AM.jpeg";
import { addProfile } from "../store/reducer/adminReducer";
import { showNotification } from "../../features/common/headerSlice";
import { toast } from "react-toastify";

export default function LabProfile() {
  const { userDetail } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false); // State to toggle the menu
  const [editPopupOpen, setEditPopupOpen] = useState(false); // State to control the edit popup
  const [name, setName] = useState(userDetail?.name || ""); // Input field for name
  const [photo, setPhoto] = useState(null); // Input field for image
  const [error, setError] = useState(""); // State for error message

  const getData = useCallback(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const logout = () => {
    Cookies.remove("token");
    localStorage.removeItem("token");
    navigate("/login");
    dispatch(user_reset());
  };

  const handleEdit = () => {
    setEditPopupOpen(true); // Open the popup when edit is clicked
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setPhoto(file); // Set the selected image file
  };

  const handleSave = () => {
    // Validate the name
    if (!name.trim()) {
      setError("Please enter your name."); // Set error message
      return; // Prevent further execution
    }
    setError(""); // Clear any previous error

    // Send name and photo separately in the API call
    const data = {
      id: userDetail?.id,
      name: name,
      photo: photo, // Ensure your API can handle this structure
    };

    let formData=new FormData()
    formData.append("name",name)
    formData.append("photo",photo)

    dispatch(addProfile(formData)).then((res) => {
      if(res.payload.success){
        toast.success(res.payload.message);
        getData();
        setEditPopupOpen(false); // Close the popup after saving
      }else{
        toast.error(res.payload.message);
        setEditPopupOpen(false); // Close the popup after saving
      }
    });
  };

  return (
    <div className="max-w-md mx-auto h-[100vh] flex flex-col justify-between shadow-lg rounded-lg overflow-hidden">
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <Link to="/">
            <IoArrowBack className="text-gray-600 text-xl" />
          </Link>
          <h1 className="text-lg font-semibold text-center text-gray-700 flex-grow">
            User Detail
          </h1>
          <button onClick={handleEdit}>
            <FiEdit className="text-blue-600 text-xl" />
          </button>
        </div>
        <div className="flex items-center flex-col justify-center">
          <img
            src={userDetail?.photo?userDetail?.photo: profile}
            alt="Lab"
            className="w-20 h-20 rounded-full object-cover"
          />
          <div className="flex-grow text-center">
            <h2 className="text-xl pt-2 text-gray-700 font-[500]">
              {userDetail?.name}
            </h2>
            <p className="text-gray-500 text-sm">{userDetail?.phone}</p>
          </div>
        </div>
        <div className="flex gap-2 items-center my-4 relative">
          <Link
            to="/forget"
            className="w-full text-center bg-white border border-[#23aafa] text-[#23aafa] py-1 rounded-full font-medium"
          >
            Change Password
          </Link>
          <div onClick={toggleMenu}>
            <FaCartPlus className="text-gray-600 text-lg h-8 w-8 rounded-lg p-2 bg-gray-200 cursor-pointer" />
          </div>

          {menuOpen && (
            <div className="absolute right-0 top-10 bg-white shadow-md rounded-lg py-2 w-48 z-10">
              <Link
                to="/orders"
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                Orders
              </Link>
            </div>
          )}
        </div>

        <div className="mt-6">
          <h3 className="font-[500] text-gray-700 text-lg mb-2">Profile</h3>
          <div className="space-y-5">
            <div className="flex items-center bg-white rounded-lg p-3">
              <div className="bg-gray-100 p-4 rounded">
                <HiOfficeBuilding className="w-7 h-7 text-gray-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-gray-600">Name</p>
                <p className="font-medium">{userDetail?.name}</p>
              </div>
            </div>
            <div className="flex items-center bg-white rounded-lg p-3">
              <div className="bg-gray-100 p-4 rounded">
                <FaPhoneSquare className="w-6 h-6 text-gray-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-gray-600">Phone</p>
                <p className="font-medium">{userDetail?.phone}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4">
        <button
          onClick={logout}
          className="w-full bg-blue-500 text-white p-3 text-sm font-medium rounded-full"
        >
          Logout
        </button>
      </div>

      {/* Edit Popup */}
      {editPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <h2 className="text-lg font-semibold text-gray-700 mb-4">
              Edit Profile
            </h2>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full border p-2 rounded-lg mb-4"
              placeholder="Enter new name"
            />
            {error && <p className="text-red-500 text-sm">{error}</p>} {/* Error message */}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full border p-2 rounded-lg mb-4"
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setEditPopupOpen(false)}
                className="px-4 py-2 bg-gray-300 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
