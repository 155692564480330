import React , { useState, useEffect, useMemo, useCallback } from "react";

import { Outlet } from "react-router-dom";

import Spinner from "./Spinner";

import { useDispatch, useSelector } from "react-redux";
import {  getUserDetails,  } from "../store/reducer/authReducer";
export default function PrivateRoute() {
    const dispatch = useDispatch();
    const { userDetail } = useSelector((state) => state.auth);
    const [ok, setOk] = useState(true);

    // Memoize the userInfo processing to avoid unnecessary computations
    const processedUserInfo = useMemo(() => {
        return userDetail ? userDetail : null;
    }, [userDetail]);
    useEffect(() => {
        if (!processedUserInfo) {
            dispatch(getUserDetails());
        }
    }, [dispatch, processedUserInfo]);

    useEffect(() => {
        setOk(!!processedUserInfo);
    }, [processedUserInfo])
    // const getData = useCallback(() => {
    //     dispatch(getUserDetails());
    //     }, [ dispatch]);

    // useEffect(() => {
    //     getData()
    //     if(!userDetail){
    //         setOk(false);
    //     }
    //     console.log("ok",ok,userDetail)
    // }, [getData]);



    return ok ? <Outlet /> : <Spinner />;
}