import React, { useState } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import {useDispatch,useSelector} from "react-redux"
import { register } from "../store/reducer/authReducer";
import { toast } from 'react-toastify';

const Register = () => {
  const {loader}=useSelector((state)=>state.auth)
const [name,setName]=useState("")
const [phone,setPhone]=useState("")
const [password,setPassword]=useState("")
const [cpassword,setCPassword]=useState("")
const dispatch=useDispatch()
const navigate=useNavigate()

const handleSubmit=async()=>{
const formData=new FormData()
formData.append("name",name)
formData.append("phone",phone)
formData.append("password",password)
formData.append("cpassword",cpassword)

 await dispatch(register(formData)).then((res)=>{

  if(res?.payload?.success){
    toast.success(res.payload.message);
    navigate("/login")
    
  }else{

    toast.error(res.payload.message);
  }
 })
}




    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const [showPassword2, setShowPassword2] = useState(false);
    const togglePasswordVisibility2 = () => {
      setShowPassword2(!showPassword2);
    };
  
  return (
    <div className="h-[100vh] p-5 relative">
    <div>
      <div className="text-3xl rounded-lg border h-8 w-8">
      <Link to='/'> <MdOutlineKeyboardArrowLeft /></Link>
      </div>
      </div>
      <div className="flex items-center flex-col h-[80%] gap-5 py-10">
        <h2 className="text-3xl font-semibold">
          Welcome Back! Glad to See You Again.
        </h2>

        <form className="flex flex-col items-center w-full mt-10">
          <input
            type="text"
            placeholder="Username"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            className="mb-4 p-4 border-[0.5px] border-border bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
          />
          <input
            type="number"
            placeholder="Mobile Number"
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
            className="mb-4 p-4 border-[0.5px] border-border bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
          />
          <div className="relative w-full mb-4">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e)=>setPassword(e.target.value)}
              className="p-4 border-[0.5px] border-border bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
            />
            <button
              type="button"
            
              onClick={togglePasswordVisibility}
              className="absolute text-gray-400 text-xl right-3 top-1/2 transform -translate-y-1/2 "
            >
              {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>
          <div className="relative w-full mb-4">
            <input
              type={showPassword2 ? "text" : "password"}
              placeholder="Confirm Password"
              value={cpassword}
              onChange={(e)=>setCPassword(e.target.value)}
              className="p-4 border-[0.5px] border-border bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility2}
              className="absolute text-gray-400 right-2 top-4 text-muted-foreground text-xl"
            >
              {showPassword2 ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>
          <Link className="bg-[#4486ff] text-white p-4 rounded-lg w-full text-center  mt-5" onClick={handleSubmit}>
            Register
          </Link>
        </form>
      </div>
      <p className="text-sm text-center font-[400] absolute bottom-3 left-1/2 transform -translate-x-1/2 w-full">Already have an account? <span className="text-[#37C2C1]"><Link to='/login'>Login here</Link></span></p>
    </div>
  )
}

export default Register
