import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import { FaRegBell } from "react-icons/fa";
import { MdPhone, MdLocationOn, MdPerson, MdMedicalServices, MdAssignment, MdAccessTime, MdDateRange, MdOutlineCurrencyRupee } from "react-icons/md";
import { getOrderbyId } from '../store/reducer/orderReducer';
import { MdPayment } from "react-icons/md";
import Spinner from '../Layout/Spinner';

const AppointmentDetails = () => {
  const { id } = useParams(); 
  const dispatch = useDispatch();
  const { orderbyid, loader } = useSelector(state => state.order); 

  useEffect(() => {
    dispatch(getOrderbyId(id));  
  }, [dispatch, id]);

  if (loader) return <Spinner/>;

  if (!orderbyid) return <div>No order found.</div>;

  const getStatusBadge = (statusNumber) => {
    switch (statusNumber) {
      case "Pending":
        return (
          <div className="bg-yellow-500 px-2 py-1 text-sm text-center text-white">
            Pending
          </div>
        );
      case "Completed":
        return (
          <div className="bg-green-500 px-2 py-1 text-sm text-center text-white">
          Completed
          </div>
        );
      case "Accepted":
        return (
          <div className="bg-purple-500 px-2 py-1 text-sm text-center text-white">
          Accepted
          </div>
        );
      case "Canceled":
        return (
          <div className="bg-red-500 px-2 py-1 text-sm text-center text-white">
           Canceled
          </div>
        );
      default:
        return <div className="badge badge-ghost">Unknown</div>;
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-start justify-center p-4">
      <div className="p-2 rounded-lg w-full max-w-2xl">
        <div className="flex items-center justify-between mb-4">
          <Link to="/orders">
            <IoArrowBack className="text-gray-600 text-xl" />
          </Link>
          <h1 className="text-lg font-semibold text-center text-gray-700 flex-grow">
            Appointment Detail
          </h1>
          {/* <FaRegBell className="text-gray-600 text-xl" /> */}
        </div>

{/* ticket design */}
{orderbyid?.status =="Accepted" &&(
<div className="w-[350px] mb-4 h-[200px] bg-[#e0c09f] text-black border-4 border-dashed border-black rounded-lg mx-auto p-4 relative">
      {/* Ticket Title */}
      <div className="text-center mb-2">
        <h2 className="text-2xl font-bold italic tracking-wider">Appointment</h2>
        <h1 className="text-3xl font-bold uppercase tracking-wider">{orderbyid?.orderId}</h1>
      </div>

      {/* Decorative Music Notes */}
      <div className="absolute top-3 left-3 text-xl">
      🩺
      </div>
      <div className="absolute top-3 right-3 text-xl">
      🏥
      </div>

      {/* Ticket Price and Details */}
      <div className="flex justify-between items-center mt-4">
        <div className="w-[80px] h-[50px] bg-black text-white text-center flex items-center justify-center font-bold text-2xl">₹{orderbyid.amount}</div>
        <div className="text-right">
          <p>{orderbyid?.time}</p>
          <p>{orderbyid?.appointment?.name}</p>
        </div>
      </div>

      {/* Perforation Line */}
      <div className="absolute top-[75%] left-0 w-full border-t-2 border-dotted border-black"></div>

      {/* Bottom Music Notes */}
      <div className="absolute bottom-3 left-3 text-xl">
      📝
      </div>
      <div className="absolute bottom-3 right-3 text-xl">
      🩺🏥
      </div>
    </div>

)}
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Status</h3>
          <span className="text-white px-2 py-1 rounded" >
          {getStatusBadge(orderbyid.status)}
          </span>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex items-start space-x-3 bg-white p-2 rounded">
            <div className="bg-gray-100 p-4 rounded">
              <MdPerson size={20} className="text-xl text-gray-400" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Patient Name</p>
              <p className="text-sm font-semibold">{orderbyid.name}</p>
            </div>
          </div>

          <div className="flex items-start space-x-3 bg-white p-2 rounded">
            <div className="bg-gray-100 p-4 rounded">
              <MdPhone size={20} className="text-xl text-gray-400" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Phone Number</p>
              <p className="text-sm font-semibold">{orderbyid.phone}</p>
            </div>
          </div>

          <div className="flex items-start space-x-3 bg-white p-2 rounded">
            <div className="bg-gray-100 p-4 rounded">
              <MdLocationOn size={20} className="text-xl text-gray-400" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Address</p>
              <p className="text-sm font-semibold">{orderbyid.address}</p>
            </div>
          </div>

          <div className="flex items-start space-x-3 bg-white p-2 rounded">
            <div className="bg-gray-100 p-4 rounded">
              <MdPayment size={20} className="text-xl text-gray-400" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Payment Staus</p>
              <p className="text-sm font-semibold">{orderbyid.payemtStatus}</p>
            </div>
          </div>

          <div className="flex items-start space-x-3 bg-white p-2 rounded">
            <div className="bg-gray-100 p-4 rounded">
              <MdAssignment size={20} className="text-xl text-gray-400" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Problem</p>
              <p className="text-sm font-semibold">{orderbyid.problem}</p>
            </div>
          </div>

          <div className="flex items-start space-x-3 bg-white p-2 rounded">
            <div className="bg-gray-100 p-4 rounded">
              <MdDateRange size={20} className="text-xl text-gray-400" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Date</p>
              <p className="text-sm font-semibold">{orderbyid.date}</p>
            </div>
          </div>

          <div className="flex items-start space-x-3 bg-white p-2 rounded">
            <div className="bg-gray-100 p-4 rounded">
            <MdOutlineCurrencyRupee size={20} className="text-xl text-gray-400" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Amount</p>
              <p className="text-sm font-semibold">{orderbyid.amount}</p>
            </div>
          </div>
        </div>
      </div>

      
    </div>
  );
}

export default AppointmentDetails;
