import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaMoneyCheckAlt } from "react-icons/fa"; // Payment icon

const PatientData = () => {
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [problem, setProblem] = useState("")
    const [age, setAge] = useState("")
    const [address, setAddress] = useState("")
   const navigate=useNavigate()
    const handleSubmit = () => {     
            const formData = {
                name,
                phone,
                address,
                problem,
                age,
                payment: localStorage.getItem("payment"),
            }
            localStorage.setItem("appointmentData", JSON.stringify(formData))
       navigate("/checkout")     
    }

    return (
        <div className=" p-5 min-h-[100vh] relative">
            <div>
                <div className="text-3xl rounded-lg border h-8 w-8">
                    <Link to='/shadule/demo-doctor'> <MdOutlineKeyboardArrowLeft /></Link>
                </div>
            </div>
            <div className="flex items-center justify-center flex-col h-full gap-5 py-2">
                <h2 className="text-xl font-semibold">
                    Enter Detail for Your Appointment.
                </h2>

                <form className="flex flex-col items-center w-full mt-2">
                    <input
                    required
                        type="text"
                        placeholder="Patient name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="mb-4 p-3 text-sm border-[0.5px] bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
                    />
                    <input
                    required
                        type="number"
                        placeholder="Mobile Number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="mb-4 p-3 text-sm border-[0.5px] bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
                    />
                    <input
                    required
                        type="number"
                        placeholder="Enter patient age"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        className="mb-4 p-3 text-sm border-[0.5px] bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
                    />
                    <input
                    required
                        type="text"
                        placeholder="Enter your address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="mb-4 p-3 text-sm border-[0.5px] bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
                    />
                    <div className="relative w-full mb-4">
                        <textarea
                            value={problem}
                            rows={3}
                            placeholder="Type Your Problem"
                            onChange={(e) => setProblem(e.target.value)}
                            className="p-3 text-sm border-[0.5px] bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
                        />
                    </div>
                

                    {/* <button
                        type="button"
                        className="flex items-center justify-center gap-2 bg-green-600 text-white p-3 rounded-lg w-full text-center mt-5 hover:bg-green-700 transition duration-300"
                        onClick={handlePayment}
                    >
                        <FaMoneyCheckAlt /> 
                        Pay 50rs
                    </button> */}

                    <button
                        type="button"
                        className={`bg-[#4486ff] text-white p-3 rounded-lg w-full text-center mt-5  `}
                        onClick={handleSubmit}
                
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}

export default PatientData
